import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Projects.css';

// Image imports
import mikrasVenturesImage from '../assets/images/mikrasventuresWeb.jpg';
import vitaliseWebImage from '../assets/images/vitaliseWeb.jpg';
import vitaliseAppImage from '../assets/images/vitaliseApp.png';
import erpImage from '../assets/images/erpWeb.png';


const projects = [
  {
    id: 1,
    title: "MikrasVentures",
    category: "ApS & Web App",
    image: mikrasVenturesImage,
    description: "CEO & Co-Founder of company developing AI Software. Site includes advanced admin control panel",
    techStack: ["React.js", "Flask", "PostgreSQL", "S3", "Redis & Celery"],
    pythonLOC: 3239,
    javascriptLOC: 6192,
    isAvailable: true,
    route: "/projects/mikrasventures",
  },
  {
    id: 2,
    title: "Vitalise.ai",
    category: "Web App",
    image: vitaliseWebImage,
    description: "A health and wellness web application",
    techStack: ["React.js", "Flask", "PostgreSQL"],
    pythonLOC: 451,
    javascriptLOC: 3293,
    isAvailable: true,
    route: "/projects/vitaliseweb",
  },
  {
    id: 3,
    title: "Vitalise.ai",
    category: "Mobile App",
    image: vitaliseAppImage,
    description: "A mobile app for Vitalise.ai - Health tracking and expert insights",
    techStack: ["React Native.js", "Flask", "PostgreSQL"],
    pythonLOC: 1581,
    javascriptLOC: 7660,
    isAvailable: true,
    route: "/projects/vitaliseapp",
  },
  {
    id: 4,
    title: "ERP System",
    category: "Web App",
    image: erpImage,
    description: "Enterprise Resource Planning system for small businesses with Integrations, Automation and AI",
    techStack: ["React Native.js", "Flask", "PostgreSQL", "Monday", "Pipedrive"],
    pythonLOC: 762,
    javascriptLOC: 1225,
    isAvailable: true,
    route: "/projects/erp",
  },
  {
    id: 5,
    title: "RecycleMe.ai",
    category: "Mobile App",
    description: "An AI-powered app for recycling and waste management using image recognition",
    techStack: ["React Native.js", "Flask", "PostgreSQL"],
    isAvailable: false,
  },
  {
    id: 6,
    title: "DataBuzTalk",
    category: "Web App",
    description: "A web application for data analysis and visualization for businesses. Integrate and utilize data from entire org. to unlock valuable insights",
    techStack: ["React.js", "Flask", "PostgreSQL"],
    isAvailable: false,
  },
  {
    id: 7,
    title: "SmartSaving4Kids",
    category: "Web App",
    description: "A web platform for teaching kids about savings and investments to foster low time preference",
    techStack: ["React.js", "Flask", "PostgreSQL"],
    isAvailable: false,
  },
  {
    id: 8,
    title: "SmartShoppingList",
    category: "Mobile App",
    description: "A mobile app to manage shopping lists and optimize shopping routes using AI",
    techStack: ["React Native.js", "Flask", "PostgreSQL"],
    isAvailable: false,
  }
];

const ProjectCard = ({ project, index, activeIndex, totalProjects }) => {
  const navigate = useNavigate();
  const theta = (2 * Math.PI) / totalProjects;
  const rotationAngle = ((index - activeIndex + totalProjects) % totalProjects) * theta;
  
  const zDistance = 500;
  const xPosition = Math.sin(rotationAngle) * zDistance;
  const zPosition = (Math.cos(rotationAngle) - 1) * zDistance;

  const isActive = index === activeIndex;

  const handleClick = () => {
    if (project.isAvailable && project.route) {
      navigate(project.route);
    }
  };

  return (
    <div 
      className={`project-card ${isActive ? 'active' : ''} ${project.isAvailable ? '' : 'unavailable'}`} 
      style={{
        transform: `translateX(${xPosition}px) translateZ(${zPosition}px) rotateY(${rotationAngle * (180 / Math.PI)}deg)`,
        opacity: Math.cos(rotationAngle) < 0 ? 0 : 1
      }}
      onClick={handleClick}
    >
      <h2>{project.title}</h2>
      <div className="category">{project.category}</div>
      {project.image && <img src={project.image} alt={project.title} className="project-image" />}
      <p>{project.description}</p>
      <div className="tech-stack">
        {project.techStack.map(tech => <span key={tech}>{tech}</span>)}
      </div>
      {project.isAvailable ? (
        <div className="loc">
          <p>Python: {project.pythonLOC} LOC</p>
          <p>JavaScript: {project.javascriptLOC} LOC</p>
        </div>
      ) : (
        <div className="coming-soon">Coming Soon</div>
      )}
    </div>
  );
};

const Projects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex((prev) => (prev - 1 + projects.length) % projects.length);
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev + 1) % projects.length);
  };

  return (
    <div className="projects-container">
      <div className="carousel">
        {projects.map((project, index) => (
          <ProjectCard 
            key={project.id} 
            project={project} 
            index={index}
            activeIndex={activeIndex}
            totalProjects={projects.length}
          />
        ))}
      </div>
      <div className="carousel-indicator">
        {projects.map((_, index) => (
          <div 
            key={index} 
            className={`indicator-dot ${index === activeIndex ? 'active' : ''}`}
          />
        ))}
      </div>
      <button className="nav-button prev" onClick={handlePrev}>&lt;</button>
      <button className="nav-button next" onClick={handleNext}>&gt;</button>
    </div>
  );
};

export default Projects;