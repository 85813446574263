// src/pages/Home.js
import React, { useState, useEffect, useRef } from 'react';
import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter';
import { Link } from 'react-router-dom';
import './Home.css';

// Custom hook for animation
const useAnimateIn = (ref, rootMargin = '0px') => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
          observer.unobserve(entry.target);
        }
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]);
};

const Home = () => {
  const sectionsRef = useRef([]);
  const statusContainerRef = useRef(null);
  const [typewriter1Done, setTypewriter1Done] = useState(false);
  const [showTypewriter2, setShowTypewriter2] = useState(false);
  const [typing, setTyping] = useState(false);

  const [text] = useTypewriter({
    words: ['research analyst', 'full-stack developer', 'stock investor'],
    loop: 0,
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 2000,
    startDelay: typewriter1Done ? 2000 : 0,
  });

  useEffect(() => {
    if (typewriter1Done) {
      const timeoutId = setTimeout(() => {
        setShowTypewriter2(true);
      }, 650); // Increase the delay before starting typewriter2
      return () => clearTimeout(timeoutId);
    }
  }, [typewriter1Done]);

  useEffect(() => {
    const typewriter1Text = document.querySelector('.typewriter1 .Typewriter__wrapper');
    if (typewriter1Text) {
      typewriter1Text.innerHTML = typewriter1Text.innerHTML.replace(
        'Rasmus Juhl',
        '<span class="highlight">Rasmus Juhl</span>'
      );
    }
  }, [typewriter1Done]);

  // Fade in sections when they are in view
  useEffect(() => {
    const handleScroll = () => {
      sectionsRef.current.forEach(section => {
        const rect = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Fade in as the section enters the bottom part of the viewport
        if (rect.top < windowHeight && rect.bottom > windowHeight * 0.5) {
          const ratio = (windowHeight - rect.top) / (windowHeight * 0.5);
          section.style.opacity = ratio > 1 ? 1 : ratio;
        }
        // Full visibility when the section is in the middle of the viewport
        else if (rect.top >= 0 && rect.bottom <= windowHeight) {
          section.style.opacity = 1;
        }
        // Fade out as the section exits the viewport from the top
        else if (rect.top < 0) {
          const ratio = 1 + rect.top / (windowHeight * 0.5);
          section.style.opacity = ratio < 0 ? 0 : ratio;
        }
        // Fade out as the section exits the viewport from the bottom
        else if (rect.bottom > windowHeight) {
          const ratio = 1 - (rect.bottom - windowHeight) / (windowHeight * 0.5);
          section.style.opacity = ratio < 0 ? 0 : ratio;
        }
      });

      // Handle status-container animation separately
      if (statusContainerRef.current) {
        const rect = statusContainerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (rect.top < windowHeight && rect.bottom > 0) {
          statusContainerRef.current.classList.add('animated');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Run on mount to set initial state

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Use JavaScript to enable smooth scrolling
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
  }, []);

  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);
  const card4Ref = useRef(null);

  useAnimateIn(card1Ref, '-100px');
  useAnimateIn(card2Ref, '-100px');
  useAnimateIn(card3Ref, '-100px');
  useAnimateIn(card4Ref, '-100px');

  return (
    <div className="page-container">
      <div className="home-container">
        <div className="spacer-section" />
        <div className="section" ref={el => sectionsRef.current[0] = el}>
          <div className="typewriter-container">
            <div className="typewriter1">
              <Typewriter
                words={['Hi, my name is Rasmus Juhl. Welcome to my site.']}
                loop={1}
                cursor={typewriter1Done ? false : true}
                cursorStyle="_"
                typeSpeed={50}
                deleteSpeed={50}
                delaySpeed={1000}
                onLoopDone={() => setTypewriter1Done(true)}
              />
            </div>
            {typewriter1Done && showTypewriter2 && (
              <div className="typewriter2">
                <Typewriter
                  words={['I am a ']}
                  loop={1}
                  cursor={false} // Disable cursor for "I am a"
                  cursorStyle="_"
                  typeSpeed={50}
                  deleteSpeed={50}
                  delaySpeed={2000}
                  onLoopDone={() => setTyping(true)}
                />
                {typing && (
                  <span>
                    <span className="highlight">{text}</span>
                    <Cursor cursorStyle="_" />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="spacer-section" />
        <div className="spacer-section" />
        <div className="section" ref={el => sectionsRef.current[1] = el}>
          <div className="status-container" ref={statusContainerRef}>
            <h2>What I'm Up To Currently</h2>
            <div className="status-cards">
              <div className="status-card" ref={card1Ref}>
                <h3>Mikras Ventures & Vitalise.ai</h3>
                <p>Co-founder working on Vitalise.ai, a mobile app using AI for personalized health advice and tools.</p>
              </div>
              <div className="status-card" ref={card2Ref}>
                <h3>Book Translation</h3>
                <p>Writing and publishing the Danish translation of "Fiat Food" by Matthew Lysiak.</p>
              </div>
              <div className="status-card" ref={card3Ref}>
                <h3>Research Analyst</h3>
                <p>Working part-time as a research analyst.</p>
              </div>
            </div>
            <div className="status-card other-projects" ref={card4Ref}>
              <h3>Other Projects</h3>
              <p>I'm always working on various projects. Check out my <Link to="/projects">Projects page</Link> for more information.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-section-small" />
    </div>
  );
};

export default Home;
