// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FloatingNavbar from './components/FloatingNavbar';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import MikrasVentures from './pages/1_MikrasVentures';
import VitaliseWeb from './pages/2_VitaliseWeb';
import VitaliseApp from './pages/3_VitaliseApp';
import ErpWeb from './pages/4_ErpWeb';
import Contact from './pages/Contact';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
      <FloatingNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/mikrasventures" element={<MikrasVentures />} />
        <Route path="/projects/vitaliseweb" element={<VitaliseWeb />} />
        <Route path="/projects/vitaliseapp" element={<VitaliseApp />} />
        <Route path="/projects/erp" element={<ErpWeb />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </div>
    </Router>
  );
};

export default App;
