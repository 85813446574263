// src/pages/4_ErpWeb.js
import React from 'react';
import './4_ErpWeb.css';

const ErpWeb = () => {
  return (
    <div className="erp-container">
      <h1>ERP System</h1>
      <a 
        href="https://erp.rasmusjuhl.com" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="view-project-btn"
      >
        View Project
      </a>
      <div className="erp-content">
        <p>
          This project showcases a streamlined ERP (Enterprise Resource Planning) system demo tailored for small businesses. 
          The system integrates seamlessly with popular productivity tools, specifically Monday for task management and Pipedrive for lead tracking. 
          It also lays the groundwork for future invoice processing capabilities.
        </p>
        <h2>Key Features:</h2>
        <ul>
          <li><strong>Task Management:</strong> Syncs and displays tasks from Monday, allowing users to view and update task statuses, priorities, and other details directly within the ERP interface.</li>
          <li><strong>Customer Relationship Management:</strong> Integrates lead information from Pipedrive, providing a centralized view of customer data.</li>
          <li><strong>Data Centralization:</strong> All integrated data is stored in a PostgreSQL database, creating a unified data repository for the business.</li>
          <li><strong>AI-Powered Insights:</strong> Leverages the collected data to provide intelligent insights and answer complex business questions through an AI assistant.</li>
          <li><strong>User-Friendly Dashboard:</strong> Offers a clean, intuitive interface for accessing various modules and visualizing key business metrics.</li>
        </ul>
        <h2>Tech Stack:</h2>
        <ul>
          <li><strong>Frontend:</strong> React.js</li>
          <li><strong>Backend:</strong> Flask (Python)</li>
          <li><strong>Database:</strong> PostgreSQL</li>
          <li><strong>API Integrations:</strong> Monday API, Pipedrive API</li>
          <li><strong>AI Integration:</strong> OpenAI's GPT model for generating insights</li>
        </ul>
        <p>
          The system demonstrates effective use of modern web technologies and API integrations. 
          The React frontend provides a responsive and interactive user experience, while the Flask backend efficiently handles data processing and API communications. 
          The use of PostgreSQL ensures robust data management and enables complex querying for AI-driven insights.
        </p>
        <p>
          This ERP demo illustrates the potential for small businesses to leverage integrated systems and AI for improved efficiency and decision-making. 
          It serves as a foundation for a more comprehensive ERP solution, showcasing skills in full-stack development, API integrations, and AI implementation in business software.
        </p>
      </div>
    </div>
  );
};

export default ErpWeb;