// src/pages/Contact.js
import React, { useState } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { MdEmail, MdContentCopy } from "react-icons/md";
import './Contact.css';

const Contact = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const email = "rasmus@mikrasventures.com";

  const handleEmailClick = () => {
    setShowEmail(!showEmail);
    setCopySuccess(false);
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(email).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="contact-container">
      <h1>Contact</h1>
      <div className="contact-icons">
        <a href="https://x.com/r_juhl" target="_blank" rel="noopener noreferrer" className="icon-link">
          <FaXTwitter className="contact-icon" />
        </a>
        <a href="https://www.linkedin.com/in/rasmus-juhl-8bb58a149/" target="_blank" rel="noopener noreferrer" className="icon-link">
          <FaLinkedin className="contact-icon" />
        </a>
        <div onClick={handleEmailClick} className="icon-link">
          <MdEmail className="contact-icon" />
        </div>
      </div>
      {showEmail && (
        <div className="email-display">
          <p>{email}</p>
          <button onClick={handleCopyEmail} className="copy-button">
            <MdContentCopy />
            {copySuccess ? "Copied!" : "Copy"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Contact;