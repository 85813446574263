// src/components/FloatingNavbar.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';
import './FloatingNavbar.css';

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

const MenuItem = ({ setActive, active, item, children }) => {
  const handleMouseEnter = () => {
    setActive(item);
  };

  const handleMouseLeave = () => {
    setActive(null);
  };

  const handleClick = () => {
    setActive(null);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="menu-item-container"
    >
      <div className="menu-item">
        <motion.p
          transition={{ duration: 0.3 }}
          className="text-black dark:text-white"
        >
          <Link to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                onClick={handleClick}>
            {item}
          </Link>
        </motion.p>
      </div>
      {active === item && children && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
          onMouseEnter={handleMouseEnter} // Keep submenu open on hover
          onMouseLeave={handleMouseLeave} // Close submenu when not hovered
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

const Menu = ({ setActive, children, darkMode, toggleDarkMode, navbarHover, setNavbarHover }) => {
  return (
    <nav
      className={`navbar ${darkMode ? 'dark-mode' : ''} ${navbarHover ? 'navbar-hover' : ''}`}
      onMouseEnter={() => setNavbarHover(true)}
      onMouseLeave={() => setNavbarHover(false)}
    >
      <div className="menu">
        {children}
      </div>
      <button onClick={toggleDarkMode} className="dark-mode-toggle">
        {darkMode ? <FaSun /> : <FaMoon />}
      </button>
    </nav>
  );
};

const FloatingNavbar = () => {
  const [active, setActive] = useState(null);
  const [darkMode, setDarkMode] = useState(true); // Default to dark mode
  const [navbarHover, setNavbarHover] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <>
      <Menu
        setActive={setActive}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        navbarHover={navbarHover}
        setNavbarHover={setNavbarHover} // Pass setNavbarHover here
      >
        <MenuItem setActive={setActive} active={active} item="Home" />
        <MenuItem setActive={setActive} active={active} item="About" />
        <MenuItem setActive={setActive} active={active} item="Projects" />
        <MenuItem setActive={setActive} active={active} item="Contact" />
      </Menu>
    </>
  );
};

export default FloatingNavbar;