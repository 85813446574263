// src/pages/About.js
import React, { useState, useEffect, useRef } from 'react';
import { FaFileAlt, FaGithub } from 'react-icons/fa';
import resumePDF from '../assets/files/Rasmus Juhl - Resume.pdf';
import './About.css';

const About = () => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!containerRef.current) return;
      const { left, top, width, height } = containerRef.current.getBoundingClientRect();
      const x = (e.clientX - left) / width - 0.5;
      const y = (e.clientY - top) / height - 0.5;
      setRotation({ x: y * 50, y: x * -50 }); // Increased rotation angle for more pronounced effect
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleResumeClick = () => {
    window.open(resumePDF, '_blank');
  };

  return (
    <div className="about-container" ref={containerRef}>
      <h1>About Me</h1>
      <div 
        className="resume-container"
        style={{
          transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`
        }}
        onClick={handleResumeClick}
      >
        <FaFileAlt className="document-icon" />
        <div className="resume-overlay">
          <p>Click to view CV</p>
        </div>
      </div>
      <a 
        href="https://github.com/R-Juhl" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="github-link"
      >
        <FaGithub />
      </a>
    </div>
  );
};

export default About;